import pathUrl from "./path"
import instance from './request'


const axios = instance;
const api = {
    // upload data =========================================================================
    // getTemplateFile() {
    //     return axios.get(pathUrl.baseUrl + "/drug_success/api-template-file");
    // },



    // postDrugSearch(post_data) {
    //     return axios({
    //         method: "post",
    //         headers: {
    //             "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    //         },
    //         data: post_data, //发送post请求，使用data关键字接收请求参数
    //         url: pathUrl.baseUrl + "/drug_success/api-drug-search",
    //     });
    // },

    getTargetExpressionOptions(target) {
        return axios.get(pathUrl.baseUrl+"/page/dataNew/selectOption/" + target+"/selectOptions.json");
    },
    getTargetExpressionPlotData(target,dataset) {
        return axios.get(pathUrl.baseUrl+"/page/dataNew/data/"  + target+"/"+dataset+".json");
    },

    getBaselinePlotData(target,dataset) {
        return axios.get(pathUrl.baseUrl+"/page/dataNew/baselineData/baselineData2/"  + target+"/"+dataset+".json");
    },
    getBaselineDatasetInfo(dataset) {
        return axios.get(pathUrl.baseUrl+"/page/dataNew/baselineData-cell-info-list2/" +dataset+".json");
    },
    getBaselineGroup(Target) {
        return axios.get(pathUrl.baseUrl+"/page/dataNew/baselineDataGroup/baselineDataGroup/" +Target+".json");
    },



    // SCPRDB
    getVolcanicMapOption(project_id) {
        return axios.get(`http://scprdb.idrblab.net/graph/VolcanicMap/data/${project_id}/options.json`);
    },
    getVolcanicMapData(project_id,gruop_name) {
        return axios.get(`http://scprdb.idrblab.net/graph/VolcanicMap/data/${project_id}/${gruop_name}.json`);
    },

    getBoxPlotOption(project_id) {
        return axios.get(`http://scprdb.idrblab.net/graph/BoxPlot/data/${project_id}/options.json`);
    },
    getBoxPlotMapData(project_id,gruop_name) {
        return axios.get(`http://scprdb.idrblab.net/graph/BoxPlot/data/${project_id}/${gruop_name}.json`);
    },

        
}

export default api;