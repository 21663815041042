<template>
  <div class="main-width">
    <BaselineGroup
      v-for="(datasets, BaselineGroup) in targetCellBaseline"
      :key="BaselineGroup"
      :label="BaselineGroup"
      :value="datasets"
      :unitTitle="BaselineGroup"
      :datasets="datasets"
      :targetID="targetID"
    >
    </BaselineGroup>
  </div>
</template>
<script>
// import "@/assets/fonts/font.css";
// import EachPlot from "@/components/EachPlot.vue";
import BaselineGroup from "@/components/BaselineGroup.vue";
// import baselineResult from "@/assets/baselineResult.json";
// import * as echarts from "echarts";
import api from "../api/index";
// import pathUrl from "../../api/path";
export default {
  name: "TargetExpressionBaseline",
  data() {
    return {
      targetID:"",
      targetCellBaseline:"",

      // diseaseExperimentOptions: [],

      // disease_name_options: [],
      // disease_name: "",
      // experiment_factor_options: [],
      // experiment_factor: "",

      // useDataset: [],

      // changeTime: 0,


    };
  },
  components: {
    // EachPlot,
    BaselineGroup,
  },
  methods: {
 
  },
  beforeMount() {
    this.targetID = this.$route.query.id;
    
    api
      .getBaselineGroup(this.targetID)
      .then((res) => {

        this.targetCellBaseline = res.data;
      });
  },
  mounted() {

  },
  // watch: {
  //   disease_name: {
  //     handler: function (o) {
  //       this.experiment_factor_options = this.diseaseExperimentOptions[o];
  //       // this.experiment_factor = this.experiment_factor_options[0].value;
  //       this.changeTime = this.changeTime + 1;
  //       if (this.changeTime > 1) {
  //         this.experiment_factor = "";
  //       }
  //     },
  //     deep: true,
  //   },
  //   experiment_factor: {
  //     handler: function (o) {
  //       if (o != "") {
  //         this.useDataset = this.experiment_factor_options.filter(
  //           (v) => v.value == o
  //         )[0].dataset;
  //       }
  //     },
  //     deep: true,
  //   },
  // },
};
</script>
<style lang="less" scoped>
</style>
