<template>
  <div class="main-width">
    <div v-if="num == 'one'" style="padding: 6px 12px;">
      <BaselineItem
        v-for="(dataset_item, dataset_index) in [
          targetCellBaseline['ICD-02: Benign/in-situ/malignant neoplasm'][0],
        ]"
        :key="dataset_index"
        :label="dataset_index"
        :value="dataset_item"
        :unitTitle="dataset_item['datasetID']"
        :divID="parseInt(Math.random() * 1000000)"
        :dataset_item="dataset_item"
        :targetID="targetID"
        :dataset_index="dataset_index"
      ></BaselineItem>

      <div
        style="
          border: 3px solid #366092;
          margin: 0 0 6px 0 !important;
          padding: 6px 1%;
          display: flex;
        "
      >
        <a
          :href="`${domain_name}/ttd/data/all-cell-baseline/details/${targetID}`"
          hreflang="en"
          target="_blank"
          style="color: #366092; margin: 0 auto;font-weight: bold;"
        >
        Click to View More Cell Type Specific Expression of This Target
          <i class="fa fa-hand-o-up" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div v-else>
      <BaselineGroup
        v-for="(datasets, BaselineGroup) in targetCellBaseline"
        :key="BaselineGroup"
        :label="BaselineGroup"
        :value="datasets"
        :unitTitle="BaselineGroup"
        :datasets="datasets"
        :targetID="targetID"
        :num="num"
      >
      </BaselineGroup>
    </div>
  </div>
</template>
<script>
// import "@/assets/fonts/font.css";
// import EachPlot from "@/components/EachPlot.vue";
import BaselineGroup from "@/components/BaselineGroup.vue";
import BaselineItem from "@/components/BaselineItem.vue";
// import baselineResult from "@/assets/baselineResult.json";
// import * as echarts from "echarts";
import api from "../api/index";
import pathUrl from "../api/path";
// import pathUrl from "../../api/path";
export default {
  name: "TargetExpressionBaseline",
  data() {
    return {
      targetID: "",
      targetCellBaseline: "",
      num: "",
      domain_name:'',
      colorUse:"#e54035",
      // diseaseExperimentOptions: [],

      // disease_name_options: [],
      // disease_name: "",
      // experiment_factor_options: [],
      // experiment_factor: "",

      // useDataset: [],

      // changeTime: 0,
    };
  },
  components: {
    // EachPlot,
    BaselineGroup,
    BaselineItem,
  },
  methods: {},
  beforeMount() {
    this.domain_name = pathUrl.baseUrl;
    this.targetID = this.$route.query.id;
    this.num = this.$route.query.num;
    // this.colorUse = `#${this.$route.query.color}`;

    api.getBaselineGroup(this.targetID).then((res) => {
      this.targetCellBaseline = res.data;
      
    });
  },
  mounted() {},
  // watch: {
  //   disease_name: {
  //     handler: function (o) {
  //       this.experiment_factor_options = this.diseaseExperimentOptions[o];
  //       // this.experiment_factor = this.experiment_factor_options[0].value;
  //       this.changeTime = this.changeTime + 1;
  //       if (this.changeTime > 1) {
  //         this.experiment_factor = "";
  //       }
  //     },
  //     deep: true,
  //   },
  //   experiment_factor: {
  //     handler: function (o) {
  //       if (o != "") {
  //         this.useDataset = this.experiment_factor_options.filter(
  //           (v) => v.value == o
  //         )[0].dataset;
  //       }
  //     },
  //     deep: true,
  //   },
  // },
};
</script>
<style lang="less" scoped>
</style>
