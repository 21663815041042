<template>
  <div class="div-unit">
    <div class="div-title" @click="show1 = !show1" style="cursor: pointer">
      <div class="div-text">
        <div class="div-rect "></div>
        {{ factor_type_map[unitTitle0] }}: <i>{{ unitTitle }}</i>
      </div>
      <div
        
        class="div-tool"
        title="Click to show or hide the detail information"
        
      >
        <i v-show="show1" class="fa fa-angle-double-down"></i>
        <i v-show="!show1" class="fa fa-angle-double-up"></i>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="show1" class="div-content" style="padding: 20px">
        <group-two
          v-for="(datasets, cell_name) in groupDataOne"
          :key="cell_name"
          :label="cell_name"
          :value="datasets"
          :unitTitle="cell_name"
          :groupDataTwo="datasets"
        ></group-two>
      </div>
    </el-collapse-transition>
  </div>
</template>
  
  <script>
import GroupTwo from "@/components/GroupTwo.vue";
export default {
  name: "GroupOne",
  data() {
    return {
      show1: true,
      factor_type_map:{
        "Cells Infected by Specific Bacterium or Virus":"The Name of Bacrerium or Virus",
        "Cells Stimulated by an Environmental Factor":"The Name of Enviromental Factor",
        "Cells Treated with Particular Molecular Drug":"The Name of Molecular Drug",
        "Expression Modification of a Protein in Cells":"The Name of Modified Protein",
      }
    };
  },
  props: {
    unitTitle0: String,
    unitTitle: String,
    groupDataOne: Object,
    // required: true,
  },
  components: {
    GroupTwo,
  },
  mounted(){
    console.log(this.datasets);
  }
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.div-rect {
  float: left;
    width: 12px;
    height: 12px;
    margin-top: 3.5px;
    margin-right: 12px;
    background: #000;
}
.div-unit {
  /* margin: 0px 0 15px 0; */
  border-radius: 10px;
}
.div-title {
  /* color: #fff; */
  border-top: 1px solid #fff;
  background: #dde4ef;
  padding: 6px 6px;
  line-height: 1.42857;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.div-title:hover {
  background: #ee770025;
}
.div-text {
  display: inline-block;
}
.div-tool {
  float: right;
  display: inline-block;
  margin-right: 5px;
}
.div-content {
  padding: 0 !important;
  
}
</style>
  