import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import 'font-awesome/css/font-awesome.min.css'
import '@/assets/style.css'

// import * as echarts from "echarts"


import axios from 'axios';
// import { v4 as uuidv4 } from 'uuid'
// 挂载到vue原型链上
Vue.prototype.axios = axios;
Vue.config.productionTip = true;

Vue.use(ElementUI,{locale})
// Vue.use(uuidv4)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



// 修改 Vue.config.productionTip = true;
// 修改 baseUrl:"http://successapi.idrblab.cn",
// npm run build
// cp -r ./dist/* /opt/django/ttd2024