<template>
  <div class="div-unit">
    <div class="div-title" @click="show1 = !show1" style="cursor: pointer">
      <div class="div-text">
        <div class="div-rect "></div>
        {{ unitTitle }}
      </div>
      <div
        
        class="div-tool"
        title="Click to show or hide the detail information"
        
      >
        <i v-show="show1" class="fa fa-angle-double-down"></i>
        <i v-show="!show1" class="fa fa-angle-double-up"></i>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="show1" class="div-content" style="padding: 20px">
        <div v-if="num=='one'">
          <BaselineItem
          v-for="(dataset_item, dataset_index) in [datasets[0]]"
          :key="dataset_index"
          :label="dataset_index"
          :value="dataset_item"
          :unitTitle="dataset_item['datasetID']"
          :divID="parseInt(Math.random() *1000000)"
          :dataset_item="dataset_item"
          :targetID = "targetID"
          :dataset_index = "dataset_index"
          :exreriment_index = "``"
        ></BaselineItem>
        </div>
        <div v-else>
          <BaselineItem
          v-for="(dataset_item, dataset_index) in datasets"
          :key="dataset_index"
          :label="dataset_index"
          :value="dataset_item"
          :unitTitle="dataset_item['datasetID']"
          :divID="parseInt(Math.random() *1000000)"
          :dataset_item="dataset_item"
          :targetID = "targetID"
          :dataset_index = "dataset_index"
          :exreriment_index = "`Experiment ${dataset_index+1}: `"
        ></BaselineItem>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>
  
  <script>
import BaselineItem from "@/components/BaselineItem.vue";
export default {
  name: "BaselineGroup",
  data() {
    return {
      show1: true,
    };
  },
  props: {
    unitTitle: String,
    datasets: Object,
    targetID:String,
    num:String,
    // required: true,
  },
  components: {
    BaselineItem,
  },
  mounted(){

    // console.log(this.datasets);
  }
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.div-rect {
  float: left;
    width: 12px;
    height: 12px;
    margin-top: 3.5px;
    margin-right: 12px;
    background: #000;
}
.div-unit {
  /* margin: 0px 0 15px 0; */
  border-radius: 10px;
}
.div-title {
  /* color: #fff; */
  border-top: 1px solid #fff;
  background: #dde4ef;
  padding: 6px 6px;
  line-height: 1.42857;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.div-title:hover {
  background: #ee770025;
}
.div-text {
  display: inline-block;
}
.div-tool {
  float: right;
  display: inline-block;
  margin-right: 5px;
}
.div-content {
  padding: 6px 0 6px 29px!important;
  
}
</style>
  