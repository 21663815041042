import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import TargetExpression from '../views/TargetExpression.vue'
import TargetExpressionBaseline from '../views/TargetExpressionBaseline.vue'
import TargetExpressionBaselineDetail from '../views/TargetExpressionBaselineDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/cell-disturbance',
    name: 'TargetExpression',
    component: TargetExpression
  },
  {
    path: '/cell-baseline1',
    name: 'TargetExpressionBaseline',
    component: TargetExpressionBaseline
  },
  {
    path: '/cell-baseline-detail',
    name: 'TargetExpressionBaselineDetail',
    component: TargetExpressionBaselineDetail
  },
  // {
  //   path: '/target-expression',
  //   name: 'TargetExpression',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/TargetExpression.vue')
  // }
  //   {
  //   path: '/scprdb-plot',
  //   name: 'scprdbPlot',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/SCPRDB/ScprdbPlot.vue')
  // },
  //   {
  //   path: '/scprdb-download',
  //   name: 'scprdbDownload',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/SCPRDB/ScprdbDownload.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
