<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div class="div-unit-each-plot">
    <div class="div-title" @click="show1 = !show1" style="cursor: pointer">
      <div class="div-text name-wrapper" style="width: 90%">
        {{ exreriment_index }}{{ title_ref[0] }}
      </div>

      <div
        class="div-tool"
        title="Click to show or hide the detail information"
      >
        <i v-show="show1" class="fa fa-angle-double-down"></i>
        <i v-show="!show1" class="fa fa-angle-double-up"></i>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="show1" class="div-content" style="padding: 20px">
        <table id="" class="box-plot-table" style="font-size: 14px">
          <colgroup>
            <col width="18%" />
            <col />
            <col width="32%" />
            <col width="16%" />
          </colgroup>
          <tbody>
            <tr>
              <th>Study Description</th>
              <td colspan="3">
                {{ title_ref[0] }}
                <a :href="`${title_ref[2]}`" target="_blank">
                  <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </td>
            </tr>
            <tr>
              <th>No. of Studied Cells</th>
              <td colspan="1">{{ cell_num }}</td>
              <td colspan="2">
                <a
                  :href="`${domain_name}/page/dataNew/baselineData/baselineData-download/${targetID}/${targetID}-${title_ref[1].replace(
                    ': ',
                    ''
                  )}.txt`"
                  target="_blank"
                  class="download-dataset"
                  download
                >
                  <i class="fa fa-download" aria-hidden="true"></i>
                  Click to Download Target Expression Data of This Experiment
                </a>
              </td>
            </tr>

            <tr>
              <td colspan="4">
                <div class="box-cells">
                  <div v-if="dataset_item['col2_or_col3'] == 'col2'">
                    <table class="cell-table">
                      <colgroup>
                        <col width="7%" />
                        <col />
                        <col width="26%" />
                      </colgroup>
                      <tr>
                        <th>Group</th>
                        <th>Group Description</th>
                        <th>Cell Origin</th>
                      </tr>
                      <tr
                        v-for="(group_item, group_index) in groups"
                        :key="group_index"
                        :label="group_item"
                        :value="group_item"
                      >
                        <td>G{{ group_index + 1 }}</td>
                        <td>{{ group_item }}</td>
                        <td>{{ groups_cell[group_index] }}</td>
                      </tr>
                    </table>
                  </div>
                  <div v-else>
                    <div style="width: 876px; margin: 0 auto; display: flex">
                      <div
                        style="width: 35%; font-weight: bold; line-height: 35px"
                      >
                        Search Cell Line Studied in This Experiment:
                      </div>
                      <div style="width: 65%">
                        <el-input
                          v-model="search"
                          size="medium"
                          placeholder="Enter keywords to search"
                        />
                      </div>
                    </div>

                    <el-table
                      :data="
                        cells_info.filter(
                          (data) =>
                            !search ||
                            data['Cell Name']
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            data['Disease']
                              .toLowerCase()
                              .includes(search.toLowerCase())||
                            data['Organism Part']
                              .toLowerCase()
                              .includes(search.toLowerCase())
                        )
                      "
                      style="width: 876px; border: 1px solid #a6a6a6"
                      max-height="300"
                      class="cell-table"
                    >
                      <el-table-column
                        label="Cell Line Name"
                        prop="Cell Name"
                        width="155"
                      >
                      </el-table-column>
                      <el-table-column label="Disease" prop="Disease">
                      </el-table-column>
                      <el-table-column label="Cell Origin" prop="Organism Part" width="165">
                      </el-table-column>
                      <el-table-column label="Cell Line ID" width="140">
                        <template slot-scope="scope">
                          <div v-if="scope.row['Cell ID'] != '.'">
                            {{ scope.row["Cell ID"] }}
                            <a
                              :href="`https://www.cellosaurus.org/${scope.row['Cell ID']}`"
                              target="_blank"
                              ><i
                                class="fa fa-external-link"
                                aria-hidden="true"
                              ></i
                            ></a>
                          </div>
                          <div v-else>N.A.</div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div style="text-align: end; padding: 0px 0 9px 0">
                  <el-switch
                    v-model="sort_by_value"
                    active-text="Sort by Value"
                    inactive-text="Sort by Name"
                  >
                  </el-switch>
                </div>
                <div style="position: relative">
                  <div class="box-plot" :id="divID"></div>
                  <div
                    style="
                      font-size: 14px;
                      font-family: 'Microsoft YaHei';
                      display: inline-block;
                      position: absolute;
                      top: 14px;
                      left: 44px;
                    "
                  >
                    <div v-if="dataset_item['col2_or_col3'] != 'col2'">Target Expression (TPM)</div>
                    <div v-else>
                      Target expression (normalized)
                      <el-tooltip class="item" effect="dark" placement="right">
                        <div slot="content" style="font-size: 14px">
                          For microarray data, the original CEL files were
                          downloaded <br />
                          and preprocessed with RMA function of oligo package
                          to<br />
                          obtain the normalized expression matrix. For RNA-seq
                          data,<br />
                          raw counts were normalized using DESeq2 package.
                        </div>
                        <i class="fa fa-question-circle"></i>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-collapse-transition>
  </div>
</template>
  
<script>
import api from "../api/index";
import * as echarts from "echarts";
import pathUrl from "../api/path";
import ExpressionAtlas2pubmed from "@/assets/ExpressionAtlas2pubmed.json";
export default {
  name: "BaselineItem",
  data() {
    return {
      show1: false,
      sort_by_value: false,
      cells_info: [
        {
          "Cell Name": "waiting...",
          Disease: "waiting...",
          "Cell ID": "waiting...",
          "Organism Part": "waiting...",
        },
      ],

      groups: "",
      groups_cell: "",
      domain_name: "",

      title_ref: ["", "", ""],
      cell_num: "",

      search: "",
    };
  },
  props: {
    unitTitle: String,
    dataset_item: Object,
    divID: String,
    targetID: String,
    dataset_index: String,
    exreriment_index: String,
    // colorUse:String,
  },
  methods: {

    plotBaseline(
      xAxis_data,
      value_data,
      disease_data,
      divID
      // colorUse=this.colorUse
      // colorUse="#e54035"
    ) {
      let dom = document.getElementById(divID);
      let myChart = echarts.init(dom, null, {
        renderer: "svg",
        useDirtyRect: true,
      });
      // value_data = value_data.map((num) => Math.log2(num+1));

      myChart.showLoading();

      let option = {
        tooltip: {
          trigger: "axis",
          extraCssText:
            "max-width:500px;white-space: normal;word-break: break-all;",
          confine: true,
          formatter: function (params) {
            // console.log(params);
            return (
              "<b>Cell Line Name: </b>" +
              params[0].axisValueLabel +
              "<br>" +
              "<b>Disease: </b>" +
              disease_data[params[0].dataIndex] +
              "<br>" +
              "<b>Target Expression: </b>" +
              params[0].data.toFixed(2)
            );
          },
          position: "inside",
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            // dataView: { readOnly: true },
            saveAsImage: {},
          },
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
            xAxisIndex: [0],
            start: 0,
            end: xAxis_data.length < 35 ? 100 : 3500 / xAxis_data.length,
          },
        ],
        grid: {
          left: "5%",
          right: "5%",
          top: "8%",
          bottom: "12%",
        },
        legend: [],
        xAxis: {
          name: "",
          nameTextStyle: {
            color: "#000",
            fontSize: 14,
            // padding:[0,0,10,10]
          },
          type: "category",
          data: xAxis_data,
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            interval: 0,
            rotate: 30,
            // color: "#e54035",
          },
        },
        yAxis: [
          {
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#000",
              fontSize: 14,
              padding: [0, 0, 10, 90],
            },
            axisLabel: {
              show: true, // 不显示坐标轴上的文字
            },
            splitLine: { show: false },
          },
        ],

        // color: [colorUse],
        color: ["#e54035"],
        series: [
          {
            name: "glyph",
            type: "pictorialBar",
            boundaryGap: false,
            barGap: "-100%",
            barMaxWidth: 30,
            symbolPosition: "end",
            symbolSize: 15,
            symbol: "pin",
            symbolOffset: [0, "-50%"],
            data: value_data,
          },
          {
            name: "glyph1",
            type: "pictorialBar",
            barMaxWidth: 30,
            boundaryGap: false,
            // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
            symbol:
              "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
            itemStyle: {
              opacity: 0.5,
            },
            emphasis: {
              itemStyle: {
                opacity: 1,
              },
            },
            data: value_data,
            z: 10,
          },
        ],
      };
      myChart.hideLoading();
      myChart.setOption(option);
      window.addEventListener("resize", myChart.resize);
    },
    plotBaselineCol2(xAxis_data, value_data, divID) {
      let dom = document.getElementById(divID);
      let myChart = echarts.init(dom, null, {
        renderer: "svg",
        useDirtyRect: true,
      });

      myChart.showLoading();

      let option = {
        tooltip: {
          trigger: "axis",
          extraCssText:
            "max-width:500px;white-space: normal;word-break: break-all;",
          confine: true,
          formatter: function (params) {
            return (
              "<b>Experimental Group: </b>" +
              xAxis_data[params[0].axisValueLabel.slice(1, 5) - 1] +
              "<br>" +
              "<b>Target Expression: </b>" +
              params[0].data.toFixed(2)
            );
          },
          position: "inside",
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            // dataView: { readOnly: true },
            saveAsImage: {},
          },
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
            xAxisIndex: [0],
            start: 0,
            end: xAxis_data.length < 35 ? 100 : 3500 / xAxis_data.length,
          },
        ],
        grid: {
          left: "5%",
          right: "5%",
          top: "8%",
          bottom: "12%",
        },
        legend: [],
        xAxis: {
          name: "",
          nameTextStyle: {
            color: "#000",
            fontSize: 14,
            // padding:[0,0,10,10]
          },
          type: "category",
          data: xAxis_data.map(function (item, index) {
            return `G${index + 1}`;
          }),
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            interval: 0,
            rotate: 30,
            // color: "#e54035",
          },
        },
        yAxis: [
          {
            type: "value",
            name: "",
            nameTextStyle: {
              color: "#000",
              fontSize: 14,
              padding: [0, 0, 10, 140],
            },
            axisLabel: {
              show: true, // 不显示坐标轴上的文字
            },
          },
        ],

        color: ["#e54035"],
        series: [
          {
            name: "glyph",
            type: "pictorialBar",
            boundaryGap: false,
            barGap: "-100%",
            barMaxWidth: 30,
            symbolPosition: "end",
            symbolSize: 15,
            symbol: "pin",
            symbolOffset: [0, "-50%"],
            data: value_data,
          },
          {
            name: "glyph1",
            type: "pictorialBar",
            barMaxWidth: 30,
            boundaryGap: false,
            // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
            symbol:
              "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
            itemStyle: {
              opacity: 0.5,
            },
            emphasis: {
              itemStyle: {
                opacity: 1,
              },
            },
            data: value_data,
            z: 10,
          },
        ],
      };
      myChart.hideLoading();
      myChart.setOption(option);
      window.addEventListener("resize", myChart.resize);
    },

  },
  beforeMount() {
    this.domain_name = pathUrl.baseUrl;
    this.title_ref = ExpressionAtlas2pubmed[this.dataset_item["datasetID"]];
    this.show1 = this.dataset_index == "0" ? true : false;
    // this.all_desscription = AllDescription["ITEM1563"];
    // this.all_desscription = AllDescription[this.datasetGroups.Item_index];
    // console.log('this.colorUse');
    // console.log(this.colorUse);
  },
  watch: {
    show1: {
      handler: function () {
        if (this.dataset_item["col2_or_col3"] == "col2") {
          api
            .getBaselinePlotData(this.targetID, this.dataset_item["datasetID"])
            .then((res) => {
              this.cell_num = res.data.xAxis.length;
              this.plotBaselineCol2(res.data.xAxis, res.data.value, this.divID);
              this.groups = res.data.xAxis;
              this.groups_cell = res.data['Organ Cell'];
            });
        } else {
          api
            .getBaselinePlotData(
              this.targetID,
              `${this.dataset_item["datasetID"]}--by_xAxis`
            )
            .then((res) => {
              this.cell_num = res.data.xAxis.length;
              this.plotBaseline(
                res.data.xAxis,
                res.data.value,
                res.data.disease,
                this.divID
              );
            });

          api
            .getBaselineDatasetInfo(this.dataset_item["datasetID"])
            .then((res) => {
              this.cells_info = res.data.cells;
            });
        }
      },
      deep: true,
    },
    sort_by_value: {
      handler: function (o) {
        if (o) {
          if (this.dataset_item["col2_or_col3"] == "col2") {
            api
              .getBaselinePlotData(
                this.targetID,
                `${this.dataset_item["datasetID"]}--by_value`
              )
              .then((res) => {
                this.cell_num = res.data.xAxis.length;
                this.plotBaselineCol2(
                  res.data.xAxis,
                  res.data.value,
                  this.divID
                );
                this.groups = res.data.xAxis;
                this.groups_cell = res.data['Organ Cell'];
              });
          } else {
            api
              .getBaselinePlotData(
                this.targetID,
                `${this.dataset_item["datasetID"]}--by_value`
              )
              .then((res) => {
                this.plotBaseline(
                  res.data.xAxis,
                  res.data.value,
                  res.data.disease,
                  this.divID
                );
              });
          }
        } else {
          if (this.dataset_item["col2_or_col3"] == "col2") {
            api
              .getBaselinePlotData(
                this.targetID,
                `${this.dataset_item["datasetID"]}--by_xAxis`
              )
              .then((res) => {
                this.cell_num = res.data.xAxis.length;
                this.plotBaselineCol2(
                  res.data.xAxis,
                  res.data.value,
                  this.divID
                );
                this.groups = res.data.xAxis;
                this.groups_cell = res.data['Organ Cell'];
              });
          } else {
            api
              .getBaselinePlotData(
                this.targetID,
                `${this.dataset_item["datasetID"]}--by_xAxis`
              )
              .then((res) => {
                this.plotBaseline(
                  res.data.xAxis,
                  res.data.value,
                  res.data.disease,
                  this.divID
                );
              });
          }
        }
      },
      deep: true,
    },
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.box-cells {
  max-height: 450px;
  overflow: auto;
  width: 100%;
  // background: #fff;
}
/deep/.el-table__cell {
  padding: 8px !important;
  border: 1px solid #a6a6a6 !important;
}
/deep/.gutter {
  border: 0px !important;
}
/deep/.el-switch__label * {
  font-size: 18px;
  font-weight: bold;
}
// /deep/.el-table__header{
//   border: 1px solid #a6a6a6!important;
// }
// .cell-table tbody tr:nth-child(odd) {
//   background: #ebebeb !important;
// }
// .cell-table tbody tr:nth-child(even) {
//   background: #f3f3f3 !important;
// }
.cell-table {
  width: 96%;
  margin: 10px auto;
  border-collapse: collapse;
  th,
  td {
    border: 1px solid #a6a6a6 !important;
    padding: 8px;
    margin: 0;
  }
  tr:nth-child(odd) {
    background: #ebebeb;
  }
  tr:nth-child(even) {
    background: #f3f3f3;
  }
}

.box-plot-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 0px;
  border: 1px solid #fff;
}
.legend-gray {
  color: #c1c1c1;
}
.group-index {
  width: 28px;
  display: inline-block;
}
.show-less {
  max-height: 60px;
  overflow: hidden;
}
.show-control {
  color: #c55a11;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 7px;
  padding: 0 8px;
  // background-image: linear-gradient(31deg, transparent, #f44336 305%);
  background-image: linear-gradient(31deg, #e8dbdf, #f44336 305%);
}
.legend {
  display: inline;
  background: #c1c1c1;
  float: left;
  width: 12px;
  height: 12px;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 6px;
}
.box-plot {
  height: 600px;
  width: 100%;
  padding-top: 10px;
  // box-shadow: 1px 1px 4px 5px #e4e8f5;
  background: #fff;
  border-radius: 6px;
}

.div-unit-each-plot {
  border: 3px solid #366092;
  margin: 0 0 6px 0 !important;
  padding: 0 1%;
}

.div-title {
  color: #366092;
  padding: 6px;
  line-height: 1.42857;
  text-align: left;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
// .div-title:hover {
//   background: #36609225;
// }
.div-text {
  display: inline-block;
}

.div-tool {
  float: right;
  display: inline-block;
  margin-right: 5px;
}

.div-content {
  padding: 25px 10px !important;
  border-top: 3px solid #366092;
}
th {
  text-align: start;
  padding-left: 8px;
}
body {
  font-size: 14px;
}
.name-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
  