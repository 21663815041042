var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-unit-each-plot"},[_c('div',{staticClass:"div-title",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.show1 = !_vm.show1}}},[_c('div',{staticClass:"div-text name-wrapper",staticStyle:{"width":"90%"}},[_vm._v(" "+_vm._s(_vm.exreriment_index)+_vm._s(_vm.title_ref[0])+" ")]),_c('div',{staticClass:"div-tool",attrs:{"title":"Click to show or hide the detail information"}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.show1),expression:"show1"}],staticClass:"fa fa-angle-double-down"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(!_vm.show1),expression:"!show1"}],staticClass:"fa fa-angle-double-up"})])]),_c('el-collapse-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show1),expression:"show1"}],staticClass:"div-content",staticStyle:{"padding":"20px"}},[_c('table',{staticClass:"box-plot-table",staticStyle:{"font-size":"14px"},attrs:{"id":""}},[_c('colgroup',[_c('col',{attrs:{"width":"18%"}}),_c('col'),_c('col',{attrs:{"width":"32%"}}),_c('col',{attrs:{"width":"16%"}})]),_c('tbody',[_c('tr',[_c('th',[_vm._v("Study Description")]),_c('td',{attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.title_ref[0])+" "),_c('a',{attrs:{"href":`${_vm.title_ref[2]}`,"target":"_blank"}},[_c('i',{staticClass:"fa fa-external-link",attrs:{"aria-hidden":"true"}})])])]),_c('tr',[_c('th',[_vm._v("No. of Studied Cells")]),_c('td',{attrs:{"colspan":"1"}},[_vm._v(_vm._s(_vm.cell_num))]),_c('td',{attrs:{"colspan":"2"}},[_c('a',{staticClass:"download-dataset",attrs:{"href":`${_vm.domain_name}/page/dataNew/baselineData/baselineData-download/${_vm.targetID}/${_vm.targetID}-${_vm.title_ref[1].replace(
                  ': ',
                  ''
                )}.txt`,"target":"_blank","download":""}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}}),_vm._v(" Click to Download Target Expression Data of This Experiment ")])])]),_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"box-cells"},[(_vm.dataset_item['col2_or_col3'] == 'col2')?_c('div',[_c('table',{staticClass:"cell-table"},[_c('colgroup',[_c('col',{attrs:{"width":"7%"}}),_c('col'),_c('col',{attrs:{"width":"26%"}})]),_c('tr',[_c('th',[_vm._v("Group")]),_c('th',[_vm._v("Group Description")]),_c('th',[_vm._v("Cell Origin")])]),_vm._l((_vm.groups),function(group_item,group_index){return _c('tr',{key:group_index,attrs:{"label":group_item,"value":group_item}},[_c('td',[_vm._v("G"+_vm._s(group_index + 1))]),_c('td',[_vm._v(_vm._s(group_item))]),_c('td',[_vm._v(_vm._s(_vm.groups_cell[group_index]))])])})],2)]):_c('div',[_c('div',{staticStyle:{"width":"876px","margin":"0 auto","display":"flex"}},[_c('div',{staticStyle:{"width":"35%","font-weight":"bold","line-height":"35px"}},[_vm._v(" Search Cell Line Studied in This Experiment: ")]),_c('div',{staticStyle:{"width":"65%"}},[_c('el-input',{attrs:{"size":"medium","placeholder":"Enter keywords to search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('el-table',{staticClass:"cell-table",staticStyle:{"width":"876px","border":"1px solid #a6a6a6"},attrs:{"data":_vm.cells_info.filter(
                        (data) =>
                          !_vm.search ||
                          data['Cell Name']
                            .toLowerCase()
                            .includes(_vm.search.toLowerCase()) ||
                          data['Disease']
                            .toLowerCase()
                            .includes(_vm.search.toLowerCase())||
                          data['Organism Part']
                            .toLowerCase()
                            .includes(_vm.search.toLowerCase())
                      ),"max-height":"300"}},[_c('el-table-column',{attrs:{"label":"Cell Line Name","prop":"Cell Name","width":"155"}}),_c('el-table-column',{attrs:{"label":"Disease","prop":"Disease"}}),_c('el-table-column',{attrs:{"label":"Cell Origin","prop":"Organism Part","width":"165"}}),_c('el-table-column',{attrs:{"label":"Cell Line ID","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row['Cell ID'] != '.')?_c('div',[_vm._v(" "+_vm._s(scope.row["Cell ID"])+" "),_c('a',{attrs:{"href":`https://www.cellosaurus.org/${scope.row['Cell ID']}`,"target":"_blank"}},[_c('i',{staticClass:"fa fa-external-link",attrs:{"aria-hidden":"true"}})])]):_c('div',[_vm._v("N.A.")])]}}])})],1)],1)])])]),_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('div',{staticStyle:{"text-align":"end","padding":"0px 0 9px 0"}},[_c('el-switch',{attrs:{"active-text":"Sort by Value","inactive-text":"Sort by Name"},model:{value:(_vm.sort_by_value),callback:function ($$v) {_vm.sort_by_value=$$v},expression:"sort_by_value"}})],1),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"box-plot",attrs:{"id":_vm.divID}}),_c('div',{staticStyle:{"font-size":"14px","font-family":"'Microsoft YaHei'","display":"inline-block","position":"absolute","top":"14px","left":"44px"}},[(_vm.dataset_item['col2_or_col3'] != 'col2')?_c('div',[_vm._v("Target Expression (TPM)")]):_c('div',[_vm._v(" Target expression (normalized) "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"right"}},[_c('div',{staticStyle:{"font-size":"14px"},attrs:{"slot":"content"},slot:"content"},[_vm._v(" For microarray data, the original CEL files were downloaded "),_c('br'),_vm._v(" and preprocessed with RMA function of oligo package to"),_c('br'),_vm._v(" obtain the normalized expression matrix. For RNA-seq data,"),_c('br'),_vm._v(" raw counts were normalized using DESeq2 package. ")]),_c('i',{staticClass:"fa fa-question-circle"})])],1)])])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }