<template>
  <div class="div-unit-each-plot">
    <div class="div-title" @click="show1 = !show1" style="cursor: pointer">
      <div class="div-text name-wrapper" style="width: 90%">
        {{ title_ref[0] }}
        <!-- {{ all_desscription["Dataset_Description"] }} -->
      </div>

      <div
        class="div-tool"
        title="Click to show or hide the detail information"
      >
        <i v-show="show1" class="fa fa-angle-double-down"></i>
        <i v-show="!show1" class="fa fa-angle-double-up"></i>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="show1" class="div-content" style="padding: 20px">
        <table id="" class="box-plot-table" style="font-size: 14px">
          <colgroup>
            <col width="18%" />
            <col />
            <col width="30%" />
            <col width="19%" />
          </colgroup>
          <tbody>
            <tr>
              <th>Study Description</th>
              <td colspan="3">
                {{ title_ref[0] }}
              </td>
            </tr>
            <tr v-if="all_desscription['cell line'] != '.'">
              <th>Cell Line</th>
              <td>
                {{ all_desscription["cell line"] }}
              </td>
              <td
                :colspan="all_desscription['cell line ID'] == '.' ? '2' : '1'"
              >
                {{ all_desscription["disease detail"] }}
              </td>
              <td v-if="all_desscription['cell line ID'] != '.'">
                <span>
                  {{ all_desscription["cell line ID"] }}
                  <a
                    :href="`https://www.cellosaurus.org/${all_desscription['cell line ID']}`"
                    target="_blank"
                  >
                    <i class="fa fa-external-link" aria-hidden="true"></i>
                  </a>
                </span>
              </td>
            </tr>
            <tr v-else>
              <th>Cell Line</th>
              <td
                :colspan="all_desscription['organism part'] == '.' ? '2' : '1'"
              >
                {{ all_desscription["cell type"] }}
              </td>

              <td v-if="all_desscription['organism part'] != '.'">
                {{ all_desscription["organism part"] }}
              </td>
              <td>
                {{ all_desscription["disease detail"] }}
              </td>
            </tr>
            <tr>
              <th>Perturbation factor</th>
              <td
                :colspan="
                  (all_desscription['Uniprot ID'] == '.') &
                  (all_desscription['Compound ID'] == '.')
                    ? '3'
                    : '2'
                "
              >
                {{ all_desscription["Experimental_factor_name"] }}
              </td>
              <td v-if="all_desscription['Uniprot ID'] != '.'" colspan="1">
                <span
                  v-for="(uniprotid, i) in all_desscription['Uniprot ID'].split(
                    '; '
                  )"
                  :key="i"
                  :label="uniprotid"
                  :value="uniprotid"
                >
                  {{ uniprotid }}
                  <a
                    :href="`https://www.uniprot.org/uniprotkb/${uniprotid}`"
                    target="_blank"
                    ><i class="fa fa-external-link" aria-hidden="true"></i
                  ></a>
                  <span
                    v-if="
                      i != all_desscription['Uniprot ID'].split('; ').length - 1
                    "
                    >;<br
                  /></span>
                </span>
              </td>
              <td v-if="all_desscription['Compound ID'] != '.'" colspan="1">
                <span
                  v-for="(cid, i) in all_desscription['Compound ID'].split(
                    '; '
                  )"
                  :key="i"
                  :label="cid"
                  :value="cid"
                >
                  CID: {{ cid }}
                  <a
                    :href="`https://pubchem.ncbi.nlm.nih.gov/compound/${cid}`"
                    target="_blank"
                    ><i class="fa fa-external-link" aria-hidden="true"></i
                  ></a>
                  <span
                    v-if="
                      i !=
                      all_desscription['Compound ID'].split('; ').length - 1
                    "
                    >;<br
                  /></span>
                </span>
              </td>
            </tr>
            <tr>
              <th colspan="1">Dataset</th>
              <td colspan="2">
                <a
                  :href="`${domain_name}/page/dataNew/data/${targetID}/${targetID}-${title_ref[1].replace(
                    ': ',
                    ''
                  )}.txt`"
                  target="_blank"
                  class="download-dataset"
                  download
                >
                  <i class="fa fa-download" aria-hidden="true"></i>
                  Click to Download Target Expression Data of This Experiment
                </a>
              </td>

              <td>
                {{ title_ref[1] }}
                <a :href="`${title_ref[2]}`" target="_blank">
                  <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </td>
            </tr>
            <tr v-if="datasetGroups.value != '.'">
              <th>Significance Test</th>
              <td colspan="3">
                {{ datasetGroups.value.length }}
                <div style="position: sticky">
                  <div :class="show_class0">
                    <div
                      v-for="(value, index) in datasetGroups.value"
                      :key="index"
                      :label="value"
                      :value="value"
                    >
                      <span
                        :style="
                          'font-weight: bold;color:' +
                          colorUse[index.split('---')[0]]
                        "
                        >G{{ xAxis.indexOf(index.split("---")[0]) + 1 }}</span
                      >
                      <span style="font-weight: bold"> vs </span>
                      <span
                        class="group-index"
                        :style="
                          'font-weight: bold;color:' +
                          colorUse[index.split('---')[1]]
                        "
                        >G{{ xAxis.indexOf(index.split("---")[1]) + 1 }}</span
                      >
                      <span style="font-weight: bold">
                        &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                      <span>
                        <b>p-adjust</b>: {{ value[0] }};&nbsp;&nbsp;
                        <b>p-value</b>: {{ value[1] }};&nbsp;&nbsp;
                        <b>LogFC</b>: {{ value[2] }}</span
                      >

                      <br />
                    </div>
                  </div>
                  <div
                    v-if="Object.keys(datasetGroups.value).length > 3"
                    class="show-control"
                    @click="showControl0"
                  >
                    <div style="width: 23px; display: inline-block">
                      <i :class="show_icon0"></i>
                    </div>
                    {{ show_str0 }}
                  </div>
                  <div v-show="showbr0"><br /></div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div style="position: sticky">
                  <div :class="show_class">
                    <div
                      v-for="(value, index) in xAxis"
                      :key="index"
                      :label="value"
                      :value="value"
                      :style="
                        xAxis[index] in colorUse
                          ? 'color: #000000;'
                          : 'color: #c1c1c1;'
                      "
                    >
                      <div
                        class="legend"
                        :style="'background-color:' + colorUse[xAxis[index]]"
                      ></div>
                      <div
                        class="legend"
                        :style="'background-color:' + colorUse[xAxis[index]]"
                      ></div>
                      <div
                        class="legend"
                        :style="'background-color:' + colorUse[xAxis[index]]"
                      ></div>
                      <span
                        class="group-index"
                        :style="
                          'font-weight: bold; padding-left: 12px;text-align: end;color:' +
                          colorUse[xAxis[index]]
                        "
                        >G{{ index + 1 }}:</span
                      >
                      {{ value }}
                    </div>
                  </div>
                  <div
                    v-if="xAxis.length > 3"
                    class="show-control"
                    @click="showControl"
                  >
                    <div style="width: 23px; display: inline-block">
                      <i :class="show_icon"></i>
                    </div>
                    {{ show_str }}
                  </div>
                  <div v-show="showbr"><br /></div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4" style="position: relative">
                <div class="box-plot" :id="divID"></div>
                <div
                  style="
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    display: inline-block;
                    position: absolute;
                    top: 290px;
                    left: -110px;
                    transform: rotate(-90deg);
                  "
                >
                  Target expression (normalized)
                  <el-tooltip class="item" effect="dark" placement="right">
                    <div slot="content" style="font-size: 14px">
                      For microarray data, the original CEL files were downloaded <br />
                      and preprocessed with RMA function of oligo package to<br />
                      obtain the normalized expression matrix. For RNA-seq data,<br />
                      raw counts were normalized using DESeq2 package.
                      
                    </div>
                    <i class="fa fa-question-circle"></i>
                  </el-tooltip>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <slot></slot>
      </div>
    </el-collapse-transition>
  </div>
</template>
  
<script>
import api from "../api/index";
import pathUrl from "../api/path";
import * as echarts from "echarts";
import AllDescription from "@/assets/all-description.json";
import ExpressionAtlas2pubmed from "@/assets/ExpressionAtlas2pubmed.json";
export default {
  name: "EachPlot",
  data() {
    return {
      show1: true,
      description: "",
      dataset_info: "",
      cellLine_info: "",
      experimentalFactorName_info: "",
      color_list: [
        "#548235",
        "#c55a11",
        "#2e75b6",
        "#bf9000",
        "rgb(212, 137, 46)",
        "rgb(153, 0, 0)",
        "rgb(247, 3, 60)",
        "rgb(122, 94, 64)",
        "rgb(133, 153, 0)",
        "rgb(79, 78, 76)",
        "rgb(19, 153, 0)",
        "rgb(205, 133, 63)",
        "rgb(160, 82, 45)",
        "rgb(0, 129, 156)",
        "rgb(0, 95, 153)",
        "rgb(0, 38, 153)",
        "rgb(67, 0, 153)",
        "rgb(153, 0, 114)",
        "rgb(208, 124, 87)",
        "rgb(250, 127, 5)",
        "rgb(143, 130, 56)",
        "rgb(79, 78, 76)",
        "rgb(255, 195, 0)",
        "rgb(178, 34, 34)",
        "rgb(175, 85, 186)",
        "rgb(139, 0, 0)",
        "rgb(46, 212, 94)",
        "rgb(118, 76, 247)",
        "rgb(212, 177, 46)",
        "rgb(255, 21, 171)",
        "rgb(212, 64, 46)",
        "#8E0018",
        "#074c7a",
      ],
      colorUse: "",
      xAxis: "",
      show_class: "show-less",
      show_str: "Click to show",
      show_icon: "fa fa-folder",
      showbr: false,
      show_class0: "show-less",
      show_str0: "Click to show",
      show_icon0: "fa fa-folder",
      showbr0: false,
      all_desscription: {
        Item_index: "",
        Dataset_ID: "",
        Dataset_Description: "",
        Experimental_factor_type: "",
        Experimental_factor_name_class: "",
        Experimental_factor_name: "",
        "Gene name": "",
        "Uniprot ID": "",
        "Protein name": "",
        "Compound name": "",
        "Compound ID": "",
        "cell type class": "",
        "organism part": "",
        "cell type": "",
        "cell line class": "",
        "cell line": "",
        "cell line ID": "",
        "disease detail": "",
        "disease class0": "",
        "disease class": "",
        "disease icd": "",
        sampleclass: "",
        Control: "",
      },
      title_ref: ["", "", ""],
      domain_name: "",
    };
  },
  props: {
    datasetID: String,
    targetID: String,
    datasetGroups: Object,
    divID: String,
  },
  methods: {
    plotRateTrend(plotvalue, plotxAxis, plotscatter, colorUse, plotid) {
      var aaa = plotscatter.map(function (item) {
        // console.log(item);
        return item[1];
      });
      // console.log(aaa);
      let dom = document.getElementById(plotid);
      let myChart = echarts.init(dom, null, {
        renderer: "svg",
        useDirtyRect: true,
      });
      // myChart.showLoading();
      let option = {
        dataset: [
          {
            // prettier-ignore
            source: plotvalue,
          },
          {
            transform: {
              type: "boxplot",
              config: {
                // itemNameFormatter: plotxAxis["{value}"],
                itemNameFormatter: function (params) {
                  // return plotxAxis[params.value];
                  return `G${params.value + 1}`;
                },
              },
            },
          },
        ],
        toolbox: {
          show: true,
          feature: {
            dataZoom: {},
            saveAsImage: {},
          },
        },
        tooltip: {
          // hideDelay: 300000,
          extraCssText:
            "max-width:500px;white-space: normal;word-break: break-all;",
          trigger: "item",
          confine: true,
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            // console.log(params);
            return `
              <b>Group Name</b>: <span>${
                plotxAxis[params.data[0].slice(1, 5) - 1]
              }</span><br>
              Maximum: <span>${params.data[5].toFixed(2)}</span><br>
              Third quartile (Q3): <span>${params.data[4].toFixed(2)}</span><br>
              <b style="color:${params.color}">Median</b>: <span style="color:${
              params.color
            }"><b>${params.data[3].toFixed(2)}</b></span><br>
              First quartile (Q1): <span>${params.data[2].toFixed(2)}</span><br>
              Minimum: <span>${params.data[1].toFixed(2)}</span><br>
              `;
          },
        },
        grid: {
          top: "4%",
          left: "5%",
          right: "1%",
          bottom: "1%",
          containLabel: true,
        },
        // dataZoom: [
        //   {
        //     type: "inside", // 放大和缩小
        //     orient: "vertical",
        //   },
        // ],
        xAxis: {
          type: "category",
          boundaryGap: true,
          //data: plotxAxis,
          axisTick: {
            alignWithLabel: false,
            show: true,
          },
          axisLine: {
            show: true, // 不显示坐标轴线
            // symbol: 'arrow',
            lineStyle: {
              // type: 'dashed',
              width: 1,
              color: "#000",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 40,
            textStyle: {
              color: "#000",
              fontSize: "18px",
            },
          },
          splitLine: {
            //网格线
            show: false,
          },
        },
        yAxis: {
          type: "value",
          scale: true,
          // triggerEvent: true,
          name: "",
          nameLocation: "center",
          nameGap: Math.max.apply(Math, aaa) > 1000 ? 60 : 50,
          nameTextStyle: {
            color: "#000",
            fontSize: "18px",
            // padding: [80, 40, 0, -20],
          },
          //min: 0,
          //max: 100,
          axisLine: {
            show: true, // 不显示坐标轴线
            // symbol: 'arrow',
            lineStyle: {
              // type: 'dashed',
              width: 1,
              color: "#000",
            },
          },
          axisTick: {
            show: true, // 不显示坐标轴刻度线
          },
          axisLabel: {
            // formatter: (params) => {
            //   return `${params}%`;
            // },
            color: "#000",
            textStyle: {
              fontSize: "18px",
            },
          },
          splitLine: {
            //网格线
            // show: false,
          },
          splitArea: {
            show: true,
          },
        },
        series: [
          {
            name: "Boxplot",
            type: "boxplot",
            datasetIndex: 1,
            colorBy: "data",
            color: plotxAxis.map(function (item) {
              return colorUse[item] ? colorUse[item] : "#c1c1c1";
            }),

            itemStyle: {
              borderWidth: 2,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          {
            name: "Each Sample",
            type: "scatter",
            data: plotscatter.map(function (item) {
              item[0] = plotxAxis.indexOf(item[0]) + 1;
              item[0] = `G${item[0]}`;
              return item;
            }),
            symbolSize: 6,
            z: 200,
            tooltip: {
              // hideDelay: 300000,
              enterable: true,
              confine: true,
              extraCssText:
                "max-width:500px;white-space: normal;word-break: break-all;",
              axisPointer: {
                type: "cross", //默认为line，line直线，cross十字准星，shadow阴影
              },
              formatter: function (param) {
                var value = param.value;
                return value[2];
              },
            },
            itemStyle: {
              color: function (param) {
                var value = param.data[0].slice(1, 5) - 1;
                value = plotxAxis[value];
                // console.log(param);
                // plotxAxis.indexOf(item[0])-1;
                return colorUse[value] ? colorUse[value] : "#c1c1c1";
              },
            },
          },

          // {
          //   name: "outlier",
          //   type: "scatter",
          //   datasetIndex: 2,
          // },
        ],
      };
      // myChart.hideLoading();
      myChart.clear();
      myChart.setOption(option);
      window.addEventListener("resize", myChart.resize);
    },
    showControl() {
      if (this.show_str == "Click to show") {
        this.show_str = "Click to hide";
        this.show_class = "show-more";
        this.show_icon = "fa fa-folder-open";
        this.showbr = true;
      } else {
        this.show_str = "Click to show";
        this.show_class = "show-less";
        this.show_icon = "fa fa-folder";
        this.showbr = false;
      }
    },
    showControl0() {
      if (this.show_str0 == "Click to show") {
        this.show_str0 = "Click to hide";
        this.show_class0 = "show-more";
        this.show_icon0 = "fa fa-folder-open";
        this.showbr0 = true;
      } else {
        this.show_str0 = "Click to show";
        this.show_class0 = "show-less";
        this.show_icon0 = "fa fa-folder";
        this.showbr0 = false;
      }
    },
  },
  beforeMount() {
    this.domain_name = pathUrl.baseUrl;
    this.title_ref = ExpressionAtlas2pubmed[this.datasetID];
    // this.all_desscription = AllDescription["ITEM1563"];
    // this.all_desscription = AllDescription[this.datasetGroups.Item_index];
    api
      .getTargetExpressionPlotData(this.targetID, this.datasetID)
      .then((res) => {
        // console.log(this.datasetGroups);
        this.description = res.data.description.Dataset_Description;
        // this.dataset_info = res.data.description.Dataset_ID;
        // this.cellLine_info = res.data.description["cell line"];
        // this.experimentalFactorName_info =
        //   res.data.description.Experimental_factor_name;
        // console.log(AllDescription[this.datasetGroups.Item_index]);
        this.all_desscription = AllDescription[this.datasetGroups.Item_index];
        // console.log(this.all_desscription);

        var color_list = this.color_list;
        var colorUse = {};

        // console.log(this.datasetGroups);

        var list_tmp = this.datasetGroups.key.split(";");
        list_tmp = list_tmp.sort(function (a, b) {
          return res.data.xAxis.indexOf(a) - res.data.xAxis.indexOf(b);
        });

        list_tmp.forEach(function (group1, index) {
          var color = color_list[index];
          colorUse[group1] = color;
          // group1
          //   .trim()
          //   .split("|||")
          //   .forEach(function (group2) {
          //     colorUse[group2.trim()] = color;
          //   });
        });
        // console.log(colorUse);
        this.colorUse = colorUse;
        this.xAxis = res.data.xAxis;

        this.plotRateTrend(
          res.data.value,
          res.data.xAxis,
          res.data.scatter,
          colorUse,
          this.divID
        );
      });
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.box-plot-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 0px;
  border: 1px solid #fff;
}
.legend-gray {
  color: #c1c1c1;
}
.group-index {
  width: 28px;
  display: inline-block;
}
.show-less {
  max-height: 60px;
  overflow: hidden;
}
.show-control {
  color: #c55a11;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 7px;
  padding: 0 8px;
  // background-image: linear-gradient(31deg, transparent, #f44336 305%);
  background-image: linear-gradient(31deg, #e8dbdf, #f44336 305%);
}
.legend {
  display: inline;
  background: #c1c1c1;
  float: left;
  width: 12px;
  height: 12px;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 6px;
}
.box-plot {
  height: 600px;
  width: 100%;
  padding-top: 10px;
  // box-shadow: 1px 1px 4px 5px #e4e8f5;
  background: #fff;
  border-radius: 6px;
}

.div-unit-each-plot {
  border: 3px solid #366092;
  margin: 0 0 6px 0 !important;
  padding: 0 1%;
}

.div-title {
  color: #366092;
  padding: 6px;
  line-height: 1.42857;
  text-align: left;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
// .div-title:hover {
//   background: #36609225;
// }
.div-text {
  display: inline-block;
}

.div-tool {
  float: right;
  display: inline-block;
  margin-right: 5px;
}

.div-content {
  padding: 25px 10px !important;
  border-top: 3px solid #366092;
}
th {
  text-align: start;
  padding-left: 8px;
}
body {
  font-size: 14px;
}
.name-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
  