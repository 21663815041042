<template>
  <div class="div-unit">
    <div class="div-title" @click="show1 = !show1" style="cursor: pointer">
      <div class="div-text">
        <div class="span-border div-circle "></div>
        Studied Cell Line Name: <i>{{ unitTitle }}</i>
        <span v-html="customHTML" class="div-unit-custom"></span>
      </div>
      <div
        
        class="div-tool"
        title="Click to show or hide the detail information"
        
      >
        <i v-show="show1" class="fa fa-angle-double-down"></i>
        <i v-show="!show1" class="fa fa-angle-double-up"></i>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="show1" class="div-content" style="padding-left: 6px 0 0 29px !important;border-top: 1px solid #fff;">
        <!-- <div
          v-for="(dataset, datasetlabel) in groupDataTwo"
          :key="datasetlabel"
          :label="datasetlabel"
          :value="dataset"
        >
          <each-plot :targetID="pageID" :datasetID="dataset" :divID="parseInt(Math.random() *1000000)"> </each-plot>
        </div> -->
        <div
          v-for="(dataset_groups, datasetlabel) in groupDataTwo"
          :key="datasetlabel"
          :label="datasetlabel"
          :value="dataset_groups"
        >
          <each-plot :targetID="pageID" :datasetID="datasetlabel" :datasetGroups="dataset_groups" :divID="parseInt(Math.random() *1000000)" style="    padding-left: 20px;"> </each-plot>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>
  
  <script>
import EachPlot from "@/components/EachPlot.vue";
export default {
  name: "GroupTwo",
  data() {
    return {
      show1: true,
      pageID:''
    };
  },
  props: {
    unitTitle: String,
    groupDataTwo: Object,
    // required: true,
  },
  components: {
    EachPlot,
  },
  mounted(){
    console.log(this.dataset);
  },
  beforeMount() {
    this.pageID = this.$route.query.id;
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.div-circle {
  float: left;
    width: 8px;
    height: 8px;
    margin-top: 3.5px;
    margin-left: 25px;
    margin-right: 12px;
    border: 2px solid black;
}
.div-unit {
  /* margin: 0px 0 15px 0 !important; */
}
.div-title {
  border-top: 1px solid #fff;
  background: #e6edf3;
  padding: 6px 6px;
  line-height: 1.42857;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.div-title:hover {
  background: #8bc34a25;
}
.div-text {
  display: inline-block;
}
.div-tool {
  float: right;
  display: inline-block;
  margin-right: 5px;
}
.div-content {
  padding: 6px 0px 6px 29px !important;
}
</style>
  