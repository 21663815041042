<template>
  <div class="main-width">
    <div style="display: flex; justify-content: space-between">
      <div class="input-title">Please Select a Preferred Disease Class:</div>
      <div class="input-title">Please Select a Perturbation Type:</div>
    </div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding: 0 6px;
        margin-bottom: 8px;
      "
    >
      <el-select
        v-model="disease_name"
        filterable
        default-first-option
        placeholder="Please select a disease name"
        style="width: 46%"
      >
        <el-option
          v-for="item in disease_name_options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <div style="font-size: 14px; margin-top: 6px; font-weight: bold">>></div>
      <el-select
        v-model="experiment_factor"
        filterable
        default-first-option
        placeholder="Please select a experiment factor"
        style="width: 46%"
      >
        <el-option
          v-for="item in experiment_factor_options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
          <span style="float: left">{{ item.label }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{
            item.hint
          }}</span>
        </el-option>
      </el-select>
    </div>
    <!-- <div
      v-for="(cell_datasets, Experimental_factor_name) in useDataset"
      :key="Experimental_factor_name"
      :label="Experimental_factor_name"
      :value="cell_datasets"
    >
      {{ Experimental_factor_name }}
      <div
        v-for="(datasets, cell_name) in cell_datasets"
        :key="cell_name"
        :label="cell_name"
        :value="datasets"
      >{{ cell_name }}
        <div
          v-for="(dataset, datasetlabel) in datasets"
          :key="datasetlabel"
          :label="datasetlabel"
          :value="dataset"
        >{{ datasetlabel }}
          <each-plot :targetID="pageID" :datasetID="dataset" :divID="parseInt(Math.random() *1000000)"> </each-plot>
        </div>
      </div>
    </div> -->

    <group-one
      v-for="(cell_datasets, Experimental_factor_name) in useDataset"
      :key="Experimental_factor_name"
      :label="Experimental_factor_name"
      :value="cell_datasets"
      :unitTitle="Experimental_factor_name"
      :unitTitle0="experiment_factor"
      :groupDataOne="cell_datasets"
    >
    </group-one>
  </div>
</template>
<script>
// import "@/assets/fonts/font.css";
// import EachPlot from "@/components/EachPlot.vue";
import GroupOne from "@/components/GroupOne.vue";
// import * as echarts from "echarts";
import api from "../api/index";
// import pathUrl from "../../api/path";
export default {
  name: "TargetExpression",
  data() {
    return {
      pageID: "",

      diseaseExperimentOptions: [],

      disease_name_options: [],
      disease_name: "",
      experiment_factor_options: [],
      experiment_factor: "",

      useDataset: [],

      changeTime: 0,
    };
  },
  components: {
    // EachPlot,
    GroupOne,
  },
  methods: {
    // plotRateTrend(graph) {
    //   let dom = document.getElementById("rate-trend");
    //   let myChart = echarts.init(dom, null, {
    //     renderer: "svg",
    //     useDirtyRect: true,
    //   });
    //   let point_icon =
    //     "path://M1240 1994 c-14 -3 -61 -16 -106 -29 -142 -43 -189 -82 -625 -521       -387 -390 -397 -401 -438 -484 -55 -113 -71 -184 -71 -305 0 -255 136 -475       364 -589 98 -49 172 -66 291 -66 153 0 260 32 375 111 30 20 233 217 451 436       325 326 403 410 432 463 107 196 113 417 17 615 -108 224 -319 361 -570 370       -52 2 -106 1 -120 -1z m264 -167 c172 -63 300 -205 341 -379 19 -83 19 -196       -1 -266 -36 -124 -67 -167 -255 -357 l-179 -180 -382 383 -383 383 175 172       c188 186 253 230 376 262 86 21 220 13 308 -18z";
    //   // let a= graph_data;
    //   // myChart.showLoading();
    //   let option = {
    //     legend: [
    //       {
    //         x: "26%",
    //         data: ["P1SR", "P2SR", "P3SR"],
    //         icon: point_icon,
    //         selectedMode: false, //取消图例上的点击事件
    //         textStyle: {
    //           fontSize: "16",
    //         },
    //       },
    //       {
    //         x: "68.5%",
    //         data: ["OSR"],
    //         icon: point_icon,
    //         selectedMode: false, //取消图例上的点击事件
    //         textStyle: {
    //           fontSize: "16",
    //         },
    //       },
    //       {
    //         x: "10%",
    //         data: ["Phase success rate:"],
    //         selectedMode: false, //取消图例上的点击事件
    //         icon: "none",
    //         textStyle: {
    //           fontSize: "16",
    //         },
    //       },
    //       {
    //         x: "52%",
    //         data: ["Overall success rate:"],
    //         selectedMode: false, //取消图例上的点击事件
    //         icon: "none",
    //         textStyle: {
    //           fontSize: "16",
    //         },
    //       },
    //     ],
    //     grid: {
    //       left: "3%",
    //       right: "3%",
    //       bottom: "1%",
    //       containLabel: true,
    //     },
    //     toolbox: {
    //       show: true,
    //       feature: {
    //         saveAsImage: {},
    //       },
    //     },
    //     xAxis: [
    //       {
    //         type: "category",
    //         boundaryGap: true,
    //         data: graph.timeline,
    //         axisTick: {
    //           alignWithLabel: false,
    //         },
    //         axisLabel: {
    //           interval: 0,
    //           rotate: 30,
    //           textStyle: {
    //             color: function (param) {
    //               //let color=['#f15c2c', '#8c3e96', '#73b73e', '#f01798', '#fbaf16', '#0097dd'];
    //               //return color[index]
    //               if (param === "From NB") {
    //                 return "#f15c2c";
    //               } else {
    //                 return "#000";
    //               }
    //             },
    //           },
    //         },
    //       },
    //     ],
    //     yAxis: [
    //       {
    //         name: "",
    //         //min: 0,
    //         //max: 100,
    //         axisLine: {
    //           show: false, // 不显示坐标轴线
    //         },
    //         axisTick: {
    //           show: false, // 不显示坐标轴刻度线
    //         },
    //         axisLabel: {
    //           formatter: (params) => {
    //             return `${params}%`;
    //           },
    //         },
    //       },
    //     ],
    //     tooltip: {
    //       //alwaysShowContent: true,
    //       trigger: "axis",
    //       axisPointer: {
    //         type: "shadow", //默认为line，line直线，cross十字准星，shadow阴影
    //         shadowStyle: { color: "#e7864038" },
    //       },
    //       enterable: true,
    //       confine: true,
    //       formatter: function (params) {
    //         var htmlStr = "";
    //         var series_arr = [];
    //         for (var i = 0; i < params.length; i++) {
    //           var param = params[i];
    //           var xName = param.name; //x轴的名称
    //           var seriesName = param.seriesName; //图例名称
    //           var value = param.value; //y轴值
    //           var color = param.color; //图例颜色
    //           //console.log(params);
    //           // eslint-disable-next-line no-empty
    //           if (series_arr.includes(seriesName)) {
    //           } else {
    //             if (i === 0) {
    //               htmlStr +=
    //                 '<div style="font-size:14px;color:#666;font-weight:400;line-height:1;">' +
    //                 xName +
    //                 "</div>"; //x轴的名称
    //             }
    //             htmlStr +=
    //               '<div style="margin: 6px 0 0;line-height:1;">' +
    //               '<div style="margin: 6px 0 0;line-height:1;">' +
    //               '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:' +
    //               color +
    //               ';"></span>' +
    //               '<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">' +
    //               seriesName +
    //               "</span>" +
    //               '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' +
    //               value.toFixed(1) +
    //               "%" +
    //               "</span>" +
    //               '<div style="clear:both"></div>' +
    //               "</div>" +
    //               '<div style="clear:both"></div>' +
    //               "</div>";
    //           }
    //           series_arr.push(seriesName);
    //         }
    //         return htmlStr;
    //       },
    //     },
    //     series: [
    //       {
    //         name: "P1SR",
    //         z: 0,
    //         type: "line",
    //         data: graph.line_s_rate1,
    //         symbol: point_icon,
    //         symbolSize: [0, 0],
    //         xAxisIndex: 1,
    //         animationDelay: 1000,
    //         animationEasing: "linear",
    //         animationDuration: 4000,
    //         itemStyle: {
    //           normal: {
    //             color: "rgb(101, 162, 210)", //改变折线点的颜色
    //             lineStyle: {
    //               color: "rgb(101, 162, 210)", //改变折线颜色
    //             },
    //           },
    //         },
    //       },
    //       {
    //         name: "P2SR",
    //         z: 0,
    //         type: "line",
    //         data: graph.line_s_rate2,
    //         symbol: point_icon,
    //         symbolSize: [0, 0],
    //         xAxisIndex: 1,
    //         animationDelay: 1000,
    //         animationEasing: "linear",
    //         animationDuration: 4000,
    //         itemStyle: {
    //           normal: {
    //             color: "rgb(235, 202, 83)", //改变折线点的颜色
    //             lineStyle: {
    //               color: "rgb(235, 202, 83)", //改变折线颜色
    //             },
    //           },
    //         },
    //       },
    //       {
    //         name: "P3SR",
    //         z: 0,
    //         type: "line",
    //         data: graph.line_s_rate3,
    //         symbol: point_icon,
    //         symbolSize: [0, 0],
    //         xAxisIndex: 1,
    //         animationDelay: 1000,
    //         animationEasing: "linear",
    //         animationDuration: 4000,
    //         itemStyle: {
    //           normal: {
    //             color: "rgb(231, 111, 108)", //改变折线点的颜色
    //             lineStyle: {
    //               color: "rgb(231, 111, 108)", //改变折线颜色
    //             },
    //           },
    //         },
    //       },
    //       {
    //         name: "OSR",
    //         z: 9,
    //         type: "line",
    //         data: graph.line_LOA1,
    //         symbol: point_icon,
    //         symbolSize: [0, 0],
    //         xAxisIndex: 1,
    //         animationDelay: 1000,
    //         animationEasing: "linear",
    //         animationDuration: 4000,
    //         itemStyle: {
    //           normal: {
    //             color: "rgb(0, 0, 0)", //改变折线点的颜色
    //             lineStyle: {
    //               color: "rgb(0, 0, 0)", //改变折线颜色
    //             },
    //           },
    //         },
    //       },
    //       {
    //         name: "P1SR",
    //         z: 1,
    //         type: "pictorialBar",
    //         data: graph.s_rate1,
    //         symbol: "diamond",
    //         symbolPosition: "end",
    //         symbolSize: [27.5, 18],
    //         symbolOffset: ["-50%", "-50%"],
    //         itemStyle: {
    //           borderColor: "rgb(101, 162, 210)",
    //           color: "rgb(101, 162, 210)",
    //         },
    //       },
    //       {
    //         name: "P1SR",
    //         z: 1,
    //         type: "bar",
    //         data: graph.s_rate1,
    //         //barWidth: 30,
    //         barGap: "-50%",
    //         itemStyle: {
    //           color: {
    //             type: "linear",
    //             x: 0,
    //             x2: 1,
    //             y: 0,
    //             y2: 0,
    //             colorStops: [
    //               { offset: 0, color: "rgba(101, 162, 210, .8)" },
    //               { offset: 0.5, color: "rgba(101, 162, 210, .8)" },
    //               { offset: 0.5, color: "rgba(101, 162, 210, .3)" },
    //               { offset: 1, color: "rgba(101, 162, 210, .3)" },
    //             ],
    //           },
    //         },
    //       },
    //       {
    //         name: "P2SR",
    //         z: 2,
    //         type: "pictorialBar",
    //         data: graph.s_rate2,
    //         symbol: "diamond",
    //         symbolPosition: "end",
    //         symbolSize: [27.5, 18],
    //         symbolOffset: ["0%", "-50%"],
    //         itemStyle: {
    //           borderColor: "rgb(235, 202, 83)",
    //           color: "rgb(235, 202, 83)",
    //         },
    //       },
    //       {
    //         name: "P2SR",
    //         z: 2,
    //         type: "bar",
    //         data: graph.s_rate2,
    //         //barWidth: 30,
    //         barGap: "-50%",
    //         itemStyle: {
    //           color: {
    //             type: "linear",
    //             x: 0,
    //             x2: 1,
    //             y: 0,
    //             y2: 0,
    //             colorStops: [
    //               { offset: 0, color: "rgba(235, 202, 83, .8)" },
    //               { offset: 0.5, color: "rgba(235, 202, 83, .8)" },
    //               { offset: 0.5, color: "rgba(235, 202, 83, .3)" },
    //               { offset: 1, color: "rgba(235, 202, 83, .3)" },
    //             ],
    //           },
    //         },
    //       },
    //       {
    //         name: "P3SR",
    //         z: 3,
    //         type: "pictorialBar",
    //         data: graph.s_rate3,
    //         symbol: "diamond",
    //         symbolPosition: "end",
    //         symbolSize: [27.5, 18],
    //         symbolOffset: ["50%", "-50%"],
    //         itemStyle: {
    //           borderColor: "rgb(231, 111, 108)",
    //           color: "rgb(231, 111, 108)",
    //         },
    //       },
    //       {
    //         name: "P3SR",
    //         z: 3,
    //         type: "bar",
    //         data: graph.s_rate3,
    //         //barWidth: 30,
    //         barGap: "-50%",
    //         itemStyle: {
    //           color: {
    //             type: "linear",
    //             x: 0,
    //             x2: 1,
    //             y: 0,
    //             y2: 0,
    //             colorStops: [
    //               { offset: 0, color: "rgba(231, 111, 108, .8)" },
    //               { offset: 0.5, color: "rgba(231, 111, 108, .8)" },
    //               { offset: 0.5, color: "rgba(231, 111, 108, .3)" },
    //               { offset: 1, color: "rgba(231, 111, 108, .3)" },
    //             ],
    //           },
    //         },
    //       },
    //       {
    //         name: "OSR",
    //         z: 4,
    //         type: "pictorialBar",
    //         data: graph.LOA1,
    //         symbol: "diamond",
    //         symbolPosition: "end",
    //         symbolSize: [0, 0],
    //         symbolOffset: ["50.5%", "-50%"],
    //         itemStyle: {
    //           borderColor: "rgb(0, 0, 0)",
    //           color: "rgb(0, 0, 0)",
    //         },
    //       },
    //       {
    //         name: "Phase success rate:",
    //         type: "line",
    //       },
    //       {
    //         name: "Overall success rate:",
    //         type: "line",
    //       },
    //     ],
    //   };
    //   option.xAxis[1] = {
    //     type: "value",
    //     max: option.xAxis[0].data.length * 100,
    //     axisPointer: {
    //       type: "none",
    //     },
    //     show: false,
    //   };
    //   //console.log(option.series[0].data);
    //   option.series[0].data = option.series[0].data.map((x, i) => [
    //     30 + 100 * i,
    //     x.value,
    //   ]);
    //   //option.series[0].data = option.series[0].data.markPoint.data.map((x, i) => [20 + 100 * i, x.value]);
    //   option.series[1].data = option.series[1].data.map((x, i) => [
    //     50 + 100 * i,
    //     x.value,
    //   ]);
    //   option.series[2].data = option.series[2].data.map((x, i) => [
    //     70 + 100 * i,
    //     x.value,
    //   ]);
    //   option.series[3].data = option.series[3].data.map((x, i) => [
    //     50 + 100 * i,
    //     x.value,
    //   ]);
    //   //option.series[4].data = option.series[4].data.map((x, i) => [80 + 100 * i, x.value]);
    //   // myChart.hideLoading();
    //   myChart.clear();
    //   myChart.setOption(option);
    //   window.addEventListener("resize", myChart.resize);
    // },
  },
  beforeMount() {
    this.pageID = this.$route.query.id;
    api.getTargetExpressionOptions(this.pageID).then((res) => {
      // this.dis_options = res.data.result;
      // console.log(res.data);
      this.diseaseExperimentOptions = res.data.diseaseExperimentOptions;
      this.disease_name_options = res.data.diseaseOptions;
      this.disease_name = this.diseaseExperimentOptions["2A00: Brain cancer"]
        ? "2A00: Brain cancer"
        : this.disease_name_options[0].value;
      // console.log(this.disease_name_options);
      // console.log(this.disease_name);
      this.experiment_factor =
        res.data.diseaseExperimentOptions[this.disease_name].reverse()[0].value;
    });
  },
  mounted() {
    // api.getSuccessRateTrend().then((res) => {
    //   this.plotRateTrendData = res.data;
    //   this.checkRateTrendData = false;
    //   this.plotRateTrend(this.plotRateTrendData[this.dis_value]);
    // });
  },
  watch: {
    disease_name: {
      handler: function (o) {
        this.experiment_factor_options = this.diseaseExperimentOptions[o];

        var a1 = this.experiment_factor_options.filter(
          (v) => v.value == "Expression Modification of a Protein in Cells"
        );
        var a2 = this.experiment_factor_options.filter(
          (v) => v.value == "Cells Treated with Particular Molecular Drug"
        );
        var a3 = this.experiment_factor_options.filter(
          (v) => v.value == "Cells Infected by Specific Bacterium or Virus"
        );
        var a4 = this.experiment_factor_options.filter(
          (v) => v.value == "Cells Stimulated by an Environmental Factor"
        );
        this.experiment_factor_options = a1.concat(a2, a3, a4);

        this.changeTime = this.changeTime + 1;
        if (this.changeTime > 1) {
          this.experiment_factor = "";
        }
      },
      deep: true,
    },
    experiment_factor: {
      handler: function (o) {
        if (o != "") {
          this.useDataset = this.experiment_factor_options.filter(
            (v) => v.value == o
          )[0].dataset;

          // console.log(o);
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
</style>
